<template>
  <div class="boutique">
    <div class="page-content">
      <div class="scoure-type">
        <template v-for="(item, index) in sourceTypeItem">
          <div
            v-if="(item.level && item.level == getLevelId) || !item.level"
            :key="index"
            :style="{
              backgroundImage:
                item.value == sourceType
                  ? 'url(' + item.url_on + ')'
                  : 'url(' + item.url + ')',
              color: item.value == sourceType ? item.color : null,
            }"
            :class="item.class"
            @click="checkType(item)"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
      <div v-if="sourceType == 0" class="paper-res-type">
        <div
          :class="{ 'type-list-on': searchItems.type == '' }"
          @click="checkTypeItem('')"
        >
          全部
        </div>
        <div
          v-for="(item, index) in examTypeOptions"
          :key="index"
          :class="{ 'type-list-on': searchItems.type == item.value }"
          @click="checkTypeItem(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div v-if="sourceType == 1" class="paper-res-type">
        <div
          :class="{ 'type-list-on': searchItems.type == '' }"
          @click="checkTypeItem('')"
        >
          全部
        </div>
        <div
          v-for="(item, index) in eliteSchoolOptions"
          :key="index"
          :class="{ 'type-list-on': searchItems.type == item.value }"
          @click="checkTypeItem(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="search-box">
        <div class="search-item">
          年级：
          <div
            :class="{
              'sea-on': searchItems.resGradeId == '',
            }"
            @click="checkGrade('')"
          >
            全部
          </div>
          <template v-if="getLevelId == 1">
            <div
              v-for="(item, index) in primaryOptions"
              :key="index"
              :class="{
                'sea-on': searchItems.resGradeId == item.value,
              }"
              @click="checkGrade(item.value)"
            >
              {{ item.label }}
            </div>
          </template>
          <template v-if="getLevelId == 2">
            <div
              v-for="(item, index) in gradeMiddleOptions"
              :key="index"
              :class="{
                'sea-on': searchItems.resGradeId == item.value,
              }"
              @click="checkGrade(item.value)"
            >
              {{ item.label }}
            </div>
          </template>
          <template v-if="getLevelId == 3">
            <div
              v-for="(item, index) in gradeHighOptions"
              :key="index"
              :class="{
                'sea-on': searchItems.resGradeId == item.value,
              }"
              @click="checkGrade(item.value)"
            >
              {{ item.label }}
            </div>
          </template>
        </div>
        <div class="search-item">
          年份：
          <div
            v-for="(item, index) in paperYears"
            :key="index"
            :class="{ 'sea-on': currentYear == item.value }"
            @click="getYearsPapers(item.value)"
          >
            {{ item.label }}
          </div>
        </div>

        <div>
          <template v-if="sourceType == 1">
            来源：
            <el-select
              v-model="resSchoolId"
              filterable
              clearable
              placeholder="请选择来源"
              @change="setLocation()"
            >
              <el-option
                v-for="item in eliteSchool"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
          地区：
          <el-cascader
            v-model="locationArr"
            placeholder="请选择地区"
            clearable
            :options="locationOptions"
            filterable
            :props="{
              value: 'code',
              label: 'name',
            }"
            @change="setLocation()"
          ></el-cascader>
        </div>
      </div>
      <div class="screen-result">
        <div class="sort-box">
          <div style="display: flex">
            <div
              v-for="(item, index) in sortTypeList"
              :key="index"
              class="sort-box"
              :class="{ 'sort-box-on': searchItems.sortType == item.value }"
              @click="checkSort(item)"
            >
              <template v-if="searchItems.sortType == item.value">
                {{ searchItems.sortOrder ? item.name : item.nameOn }}</template
              >
              <template v-if="searchItems.sortType != item.value">
                {{ item.nameOn }}</template
              >

              <div class="sort-icon">
                <i
                  class="el-icon-top"
                  :class="{ 'sort-on': searchItems.sortOrder == 1 }"
                >
                </i>
                <i
                  class="el-icon-bottom"
                  :class="{ 'sort-on': searchItems.sortOrder == 0 }"
                >
                </i>
              </div>
            </div>
          </div>
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入试卷名称"
            @keyup.enter.native="getPaperList()"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="getPaperList"
            >
            </i>
          </el-input>
        </div>
        <div v-loading="fullscreenLoading" class="paper-list">
          <div v-if="paperList.length > 0" class="has-data">
            <div v-for="(item, key) in paperList" :key="key" class="list-item">
              <div class="head">
                <div class="title-box">
                  <div class="title" @click="paperView(item.id)">
                    {{ nullDataProcessing(item.name) }}
                  </div>
                  <div class="type">
                    <span
                      v-if="sourceType == 0 || sourceType == 1"
                      :class="setPaperTypeBgcolor(item.type)"
                      class="com"
                    >
                      {{ getLabelByValue(item.type, examTypeOptions) }}
                    </span>
                  </div>
                </div>
                <div class="head-right-box">
                  <span>浏览量：{{ item.countView }}</span>
                  <span>收藏：{{ item.countFav }}</span>
                </div>
              </div>
              <div class="paper-tips-box">
                <div v-if="getshowTips(item, '1')">PDF</div>
                <div v-if="getshowTips(item, '2')">图片</div>
                <div v-if="getshowTips(item, '3')" class="word-tips">Word</div>
                <div v-if="item.statusStep >= 3" class="cut-tips">已裁题</div>
                <div v-if="item.statusStep < 3">未裁题</div>
              </div>
              <div class="label-cnt">
                <div class="text">
                  <!-- province  resGradeId  type countQues  countView  countFav -->
                  <div style="display: flex">
                    <!-- 地区 -->
                    <div class="normal">
                      {{ getAddress(item.province, item.city) }}
                    </div>
                    <template v-if="sourceType == 1 && item.resSchoolName">
                      <div class="normal">
                        {{ item.resSchoolName }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="normal">
                        {{ item.schoolName }}
                      </div>
                    </template>

                    <!-- 年级 -->
                    <div v-if="!dataIsEmpty(item.resGradeId)" class="normal">
                      {{ getLabelByValue(item.resGradeId, gradeOptions) }}
                    </div>
                  </div>
                  <div class="text-btm">
                    <!-- 题量 -->
                    <div v-if="item.statusStep >= 3" class="normal">
                      {{ "共" + item.countQues }}题
                    </div>

                    <div
                      v-if="!dataIsEmpty(item.collectionTime)"
                      class="normal"
                    >
                      收录于：{{ item.collectionTime }}
                    </div>
                  </div>
                </div>
                <div class="btns">
                  <el-button plain @click="paperView(item.id)">
                    <img src="@/assets/resourse/paper/show.png" alt="" />
                    <img
                      class="on"
                      src="@/assets/resourse/paper/show_on.png"
                      alt=""
                    />
                    查看
                  </el-button>
                  <el-button
                    plain
                    :class="{ 'el-button-on': item.relationId > 0 }"
                    @click="checkFav(item)"
                  >
                    <img src="@/assets/resourse/paper/collect.png" alt="" />
                    <img
                      class="on"
                      src="@/assets/resourse/paper/collect_on.png"
                      alt=""
                    />

                    <template v-if="item.relationId > 0"> 已收藏</template>
                    <template v-else> 收藏</template>
                  </el-button>
                  <el-button plain @click="downloadExamPaper(item)">
                    <img src="@/assets/resourse/paper/down.png" alt="" />
                    <img
                      class="on"
                      src="@/assets/resourse/paper/down_on.png"
                      alt=""
                    />
                    原卷下载
                  </el-button>
                  <el-button
                    v-if="item.statusStep >= 3 || item.statusStep >= 3"
                    plain
                    class="fx-btn"
                    @click="paperAnalysis(item.id)"
                  >
                    <img src="@/assets/resourse/paper/analyse.png" alt="" />
                    <img
                      class="on"
                      src="@/assets/resourse/paper/analyse_on.png"
                      alt=""
                    />
                    试卷分析
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <no-data v-else message="暂无数据"></no-data>
        </div>
      </div>
    </div>
    <el-pagination
      v-if="totalPage > 0"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!-- end: pagination-->
    <!--试卷分析弹窗-->
    <ExamPaperAnalysisForm
      v-if="analysisDialogVisible"
      ref="ExamPaperAnalysisForm"
    ></ExamPaperAnalysisForm>
  </div>
</template>
<script>
const resTypeList = [
  {
    value: 0,
    label: "全部资源",
  },
  {
    value: 1,
    label: "名校资源",
  },
];

const linkList = [
  //   国家中小学智慧教育云平台
  // https://www.zxx.edu.cn/syncClassroom
  // 第一课件网
  // https://www.1ppt.com/kejian/
  // 学科网
  // https://www.zxxk.com/
  // 菁优网
  // http://www.jyeoo.com/
  {
    value: "https://www.zxx.edu.cn/syncClassroom",
    label: "国家中小学智慧教育云平台",
  },
  {
    value: "https://www.1ppt.com/kejian/",
    label: "第一课件网",
  },
  {
    value: "https://www.zxxk.com/",
    label: "学科网",
  },
  {
    value: "http://www.jyeoo.com/",
    label: "菁优网",
  },
];
import {
  getLabel,
  subjectOptions,
  gradeOptions,
  gradeMiddleOptions,
  primaryOptions,
  gradeHighOptions,
  examTypeOptions,
  schoolLevelOptions,
  eliteSchoolOptions,
} from "@/core/util/constdata";
import {
  fetchList,
  getCountOfPaperTypes,
} from "@/core/api/resource/boutiqueLibrary";
import {
  getProvinceList,
  getCityList,
  getDistrictList,
} from "@/core/api/cms/geo";
import { mapGetters } from "vuex";
import ExamPaperAnalysisForm from "@/views/resource/form/ExamPaperAnalysisForm";
import {
  getexampaperzip,
  updateExamPaperCountView,
} from "@/core/api/resource/exampaper";
import { formatDate } from "@/core/util/date";
import { resschoolList } from "@/core/api/school/school";
import { favObj, unfavObj } from "@/core/api/resource/myResource";
import { listCommonTree } from "@/core/api/resource/knowledgePointsPaper";
import { getStore } from "@/core/util/store";
const sortTypeList = [
  {
    name: "按最近时间",
    nameOn: "按最远时间",
    value: 1,
  },
  {
    name: "按最少浏览",
    nameOn: "按最多浏览",
    value: 2,
  },
];
const sourceTypeItem = [
  {
    name: "小考真题",
    value: 11,
    level: 1,
    url: require("@/assets/resourse/paper/past.png"),
    url_on: require("@/assets/resourse/paper/past_on.png"),
    color: "#3862DF",
    class: "type-one",
  },
  {
    name: "中考真题",
    value: 12,
    level: 2,
    url: require("@/assets/resourse/paper/past.png"),
    url_on: require("@/assets/resourse/paper/past_on.png"),
    color: "#3862DF",
    class: "type-one",
  },
  {
    name: "高考真题",
    value: 13,
    level: 3,
    url: require("@/assets/resourse/paper/past.png"),
    url_on: require("@/assets/resourse/paper/past_on.png"),
    color: "#3862DF",
    class: "type-one",
  },
  {
    name: "名校资源",
    value: 1,
    url: require("@/assets/resourse/paper/eliteSchool.png"),
    url_on: require("@/assets/resourse/paper/eliteSchool_on.png"),
    color: "#00AAF4",
    class: "type-two",
  },
  {
    name: "常规资源",
    value: 0,
    url: require("@/assets/resourse/paper/common.png"),
    url_on: require("@/assets/resourse/paper/common_on.png"),
    color: "#FF8000",
    class: "type-three",
  },
];
export default {
  name: "Boutique",
  components: {
    ExamPaperAnalysisForm,
  },
  data() {
    return {
      resSchoolId: "",
      eliteSchool: [],
      locationArr: [],
      locationOptions: [],
      sortTypeList: sortTypeList,

      resTypeList: resTypeList,
      sourceTypeItem: sourceTypeItem,
      eliteSchoolOptions: eliteSchoolOptions,

      sourceType: 1,
      linkList: linkList,
      fullscreenLoading: false,

      currentLevel: this.$route.query.level, //年级
      currentSubject: this.$route.query.subject,
      // 分类
      paperTypeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      paperCountOfTypes: [], //各类型试卷数量
      currentSelectedPaperType: 0,
      // 试题
      paperList: [],
      latestTestPaper: [], //最新试卷
      hotestTestPaper: [], //最热试卷
      provinceList: [],
      cityList: [],
      districtList: [],
      areaPopupVisible: false, //省级弹窗
      cityItemsVisible: false, //市级选项展示
      cityPopupVisible: false, //市级弹窗
      districtItemsVisible: false, //区级选项展示
      districtPopupVisible: false, //区级弹窗

      getLabel: getLabel,
      subjectOptions: subjectOptions,
      gradeOptions: gradeOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      primaryOptions: primaryOptions,
      gradeHighOptions: gradeHighOptions,
      examTypeOptions: examTypeOptions,
      schoolLevelOptions: schoolLevelOptions,

      searchItems: {
        level: 0, //学段
        subjectId: 0, //科目
        year: 0, //年份
        beforeYear: 0, //更早: 全部-year=0,beforeYear=0 具体某年份-year=年份,beforeYear=0 更早-year=0,beforeYear=列举出来的最小年份
        province: "", //省
        city: "", //市
        district: "", //区
        resGradeId: 0, //年级
        type: 0,
        latest: 1, //0-不开启 1-开启
        viewOrder: 2, //1-升序 2-降序
        orderType: 1, //1-按时间排序 2-按浏览数排序
        keyword: "",
        isImportant: 0,

        sortType: 1,
        sortOrder: 0,
        sortOrderCopy: "",
      },
      paperType: 0, //type：1-最新 2-最热
      isCurrentSortType: "new",
      currentProvinceId: 0,
      currentCityId: 0,
      currentDistrictId: 0,
      paperYears: [],
      currentYear: 0,
      searchPopupVisibel: false,
      analysisDialogVisible: false, // 试卷分析弹窗

      // 列表项
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,

      CDN: "https://static.wtjy.com/",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
    hotPaperScrollOption() {
      return {
        step: 0.5, //数值越大滚动越快
      };
    },
  },
  created() {
    this.searchItems.level = this.getLevelId;
    this.searchItems.subjectId = this.getSubjectId;
    this.initView();
    this.getCountOfPaperTypes();

    this.getPaperList(1);
    this.getlistTree();
    this.getSchool();
  },
  methods: {
    getSchool() {
      let schoolInfo = getStore({ name: "schoolInfo" });
      resschoolList().then((res) => {
        let arr = res.data.data.filter((item) => item.id == schoolInfo.id);
        if (arr.length == 0) {
          res.data.data = [{ id: schoolInfo.id, name: schoolInfo.name }].concat(
            res.data.data
          );
        }

        this.eliteSchool = res.data.data;
      });
    },
    initView() {
      //筛选试卷条件--年份
      let _year = new Date().getFullYear();
      this.paperYears = [{ label: "全部", value: 0 }];
      for (let i = _year; i > _year - 5; i--) {
        let _item = { label: i, value: i };
        this.paperYears.push(_item);
      }
      this.paperYears.push({ label: "更早", value: 1 });
      this.sourceType = 0;
      // if (this.getLevelId == 1) {
      //   this.sourceType = 11;
      // }
      // if (this.getLevelId == 2) {
      //   this.sourceType = 12;
      // }
      // if (this.getLevelId == 3) {
      //   this.sourceType = 13;
      // }
    },
    setLocation() {
      this.pageIndex = 1;
      // console.log(this.locationArr);
      this.getPaperList(1);
    },
    checkSort(item) {
      if (item.value == this.searchItems.sortType) {
        this.searchItems.sortOrder = this.searchItems.sortOrder == 0 ? 1 : 0;
      } else {
        this.searchItems.sortType = item.value;
        this.searchItems.sortOrder = 0;
      }
      this.pageIndex = 1;

      this.getPaperList(1);
    },
    getlistTree() {
      let data = { maxType: 1 };
      listCommonTree(data).then((res) => {
        this.locationOptions = res.data.data.map((item) => {
          item.children.map((index) => {
            if (index.children.length == 0) {
              delete index.children;
            }
          });
          if (item.children.length > 0) {
            item.children = [
              {
                code: "-1",
                name: "全部",
              },
            ].concat(item.children);
          }

          return item;
        });
      });
    },
    async getLocation(node, resolve) {
      const { level, value } = node;
      if (level == 0) {
        await this.getProvince();
        resolve(this.provinceList);
      }
      if (level == 1) {
        await this.getCity(value);
        resolve(this.cityList);
      }
      if (level == 2) {
        await this.getDistrict(value);
        resolve(this.districtList);
      }
    },
    async getProvince() {
      //地区
      await getProvinceList().then((res) => {
        let list = res.data.data || [];
        this.provinceList = [{ label: "全部", value: "0", leaf: true }].concat(
          list
        );
      });
    },
    // 市
    async getCity(province) {
      await getCityList({ province: province }).then((res) => {
        let list = res.data.data || [];
        this.cityList = [{ label: "全部", value: "0", leaf: true }].concat(
          list
        );
      });
    },
    // 区
    getDistrict(city) {
      getDistrictList({ city: city }).then((res) => {
        let list = res.data.data.map((item) => {
          item.leaf = true;
          return item;
        });
        this.districtList = [{ label: "全部", value: "0", leaf: true }].concat(
          list
        );
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPaperList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getPaperList();
    },
    getCountOfPaperTypes() {
      // 各类型试卷数量
      getCountOfPaperTypes({
        level: this.getLevelId,
        subjectId: this.getSubjectId,
        isImportant: this.searchItems.isImportant,
      }).then((res) => {
        this.paperCountOfTypes = res.data.data;
        this.paperTypeDataProcessing();
      });
    },
    setType(val) {
      this.searchItems.isImportant = val;
      this.getCountOfPaperTypes();
      this.getPaperList();
    },
    checkType(val) {
      if (this.sourceType == val.value) {
        return;
      }
      this.sourceType = val.value;
      this.searchItems.type = "";
      this.getPaperList(1);
    },
    checkTypeItem(val) {
      this.pageIndex = 1;
      this.searchItems.type = val;
      this.getPaperList(1);
    },
    openLink(link) {
      // console.log(link);
      window.open(link);
    },
    handleChange() {
      this.searchItems.province = this.provinceAndCityId[0];
      this.searchItems.city = this.provinceAndCityId[1];
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    nullDataProcessing(field) {
      if (this.dataIsEmpty(field)) {
        return "--";
      } else {
        return field;
      }
    },
    avoidEqualToZero(field) {
      return field + parseInt(10 * Math.random() + 1);
    },
    getLabelByValue(value, source) {
      let _label = "";
      source.forEach((item) => {
        if (item.value == value) {
          _label = item.label;
        }
      });
      return _label;
    },
    getAreaLabelByValue(value, source) {
      let _label = "";
      source.forEach((item) => {
        if (item.value == value) {
          _label = item.label.substring(0, 2);
          if (value == 15 || value == 23) {
            _label = item.label.substring(0, 3);
          }
        }
      });
      return _label;
    },
    setPaperTypeBgcolor(type) {
      return "bgcolor_" + type;
    },
    formatDate(dateStr) {
      if (!dateStr || dateStr.length === 0) {
        return "";
      }
      let date = new Date(dateStr);
      return formatDate(date, "yyyy-MM-dd");
    },
    getshowTips(item, file) {
      // console.log(item.filePath)
      let type = false;
      if (item.filePath) {
        if (file == 1) {
          if (item.filePath.endsWith("pdf")) {
            type = true;
          }
        }
        if (file == 3) {
          if (item.filePath.endsWith("doc") || item.filePath.endsWith("docx")) {
            type = true;
          }
        }
        if (file == 2) {
          if (
            !item.filePath.endsWith("doc") &&
            !item.filePath.endsWith("docx") &&
            !item.filePath.endsWith("pdf")
          ) {
            type = true;
          }
        }
      }
      return type;
    },
    getAddress(province, city) {
      let _province = "";
      let _city = "";
      if (!this.dataIsEmpty(city)) {
        _city = city.split("-")[0];
      }
      if (!this.dataIsEmpty(province)) {
        _province = province.split("-")[0];
      } else {
        _province = "全国";
        _city = "";
      }

      if (_city == _province) {
        _city = "";
      }
      return _province + _city;
    },

    //试卷分类数据处理
    paperTypeDataProcessing() {
      this.paperTypeData = this.gradeMiddleOptions;
      if (this.getLevelId == 3) {
        this.paperTypeData = this.gradeHighOptions;
      }
      if (this.getLevelId == 1) {
        this.paperTypeData = this.primaryOptions;
      }
      this.paperTypeData.forEach((item) => {
        item.count = 0; //一级节点统计数count
        item.children = []; //二级节点
        this.paperCountOfTypes.forEach((val) => {
          if (item.value == val.resGradeId) {
            item.count += parseInt(val.count);
            item.children.push(val);
          }
        });
        item.children.forEach((index) => {
          index.label = this.getLabelByValue(index.type, this.examTypeOptions);
        });
      });
    },

    /**
     * banner列表数据
     * type(paperType)：1-最新试卷 2-最热试卷
     */
    getPaperForBanner(type) {
      if (type == 1) {
        this.searchItems.latest = 1;
        this.searchItems.orderType = 1;
        fetchList(
          Object.assign(
            {
              current: 1,
              size: 10,
            },
            this.searchItems
          )
        ).then((res) => {
          let _list = res.data.data.records;
          this.latestTestPaper = _list;
        });
      } else if (type == 2) {
        this.searchItems.latest = 0;
        this.searchItems.viewOrder = 2;
        this.searchItems.orderType = 2;
        fetchList(
          Object.assign(
            {
              current: 1,
              size: 50,
              isImportant: 1, //重点中学
            },
            this.searchItems
          )
        ).then((res) => {
          let _list = res.data.data.records;
          this.hotestTestPaper = _list;
        });
      }
    },

    //试卷列表
    getPaperList() {
      this.fullscreenLoading = true;
      let data = Object.assign(
        {
          current: this.pageIndex,
          size: this.pageSize,
        },
        this.searchItems
      );
      // if (data.sortType == 1) {
      //   data.sortOrder = data.sortOrderCopy;
      // }
      if (this.locationArr.length > 0) {
        data.province = this.locationArr[0];
        data.city = this.locationArr[1] == "-1" ? "" : this.locationArr[1];
        // data.district = this.locationArr[2];
      }
      data.resSchoolId = this.resSchoolId;
      if (
        this.sourceType == 11 ||
        this.sourceType == 12 ||
        this.sourceType == 13
      ) {
        data.category = this.sourceType;
        data.type = 0;
      } else {
        data.category = this.sourceType;
        data.isImportant = this.sourceType;
      }

      fetchList(data)
        .then((res) => {
          this.fullscreenLoading = false;
          let _list = res.data.data.records;
          this.totalPage = res.data.data.total;
          this.pageSize = res.data.data.size;

          this.paperList = _list.map((item) => {
            if (item.type == 0) {
              item.type = 99;
            }
            return item;
          });
        })

        .catch(() => {
          this.fullscreenLoading = false;
        });
    },

    //获取试卷--年份
    setSearchYears(year) {
      //year:0-全部 1-更早
      if (year == 0) {
        this.currentYear = 0;
        this.searchItems.year = 0;
        this.searchItems.beforeYear = 0;
      } else if (year == 1) {
        this.currentYear = 1;
        this.searchItems.year = 0;
        this.searchItems.beforeYear = new Date().getFullYear() - 5 + 1;
      } else {
        this.currentYear = year;
        this.searchItems.year = year;
        this.searchItems.beforeYear = 0;
      }
    },
    getYearsPapers(year) {
      this.pageIndex = 1;
      this.setSearchYears(year);
      this.getPaperList(1);
    },
    checkGrade(val) {
      this.searchItems.resGradeId = val;
      this.pageIndex = 1;
      this.getPaperList(1);
    },

    //查看试卷
    paperView(id) {
      this.$router.push({
        name: "viewPaper",
        query: {
          id: id,
        },
      });
    },
    checkFav(item) {
      if (item.relationId) {
        this.delFav(item);
      } else {
        this.addFav(item);
      }
    },
    addFav(item) {
      favObj(item.id).then(() => {
        this.setList(item.id, false);
        this.$message.success("收藏成功");
      });
    },
    delFav(item) {
      this.$confirm("确认取消收藏当前试卷吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unfavObj(item.id).then(() => {
            this.setList(item.id, true);
            this.$message.success("取消收藏成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“取消收藏”操作",
          });
        });
    },
    setList(id, type) {
      this.paperList.map((index) => {
        if (id == index.id) {
          if (type) {
            index.relationId = 0;
          } else {
            index.relationId = 1;
          }
        }
      });
      // this.pageData = JSON.parse(JSON.stringify(this.pageData));
    },
    //试卷分析
    paperAnalysis(id) {
      this.analysisDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ExamPaperAnalysisForm.init(id);
      });
    },
    //试卷下载
    downloadExamPaper(examPaper) {
      if (!examPaper.filePath && !examPaper.zipPath) {
        this.$message.error("试卷文件不存在！");
        return;
      }
      // 下载试卷增加试卷的浏览数
      updateExamPaperCountView(examPaper.id);
      // if (
      //   examPaper.filePath.endsWith(".doc") ||
      //   examPaper.filePath.endsWith(".docx") ||
      //   examPaper.filePath.endsWith(".pdf")
      // ) {
      //   this.downloadRequest(this.CDN + examPaper.filePath, examPaper.name);
      // } else {
      this.fullscreenLoading = true;
      getexampaperzip(examPaper.id)
        .then((response) => {
          this.downloadRequest(response.data.data.url, response.data.data.name);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
      // }
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.send();
    },
  },
};
</script>

<style lang="scss" scoped>
.boutique {
  width: 1220px;
  margin: 0 auto 50px;
  .sort-box {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
    cursor: pointer;
    padding: 2px 6px;
    .sort-icon {
      margin-left: 2px;
      i {
        display: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .sort-box-on {
    color: #ffffff;
    background-color: #2474ed;
    border-radius: 4px;
    .sort-icon {
      .sort-on {
        display: block;
      }
    }
  }
  .page-content {
    padding: 24px;
    .search-box {
      background: #f9fafc;
      padding: 10px;
      margin-top: 14px;
      .search-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        > div {
          padding: 4px 16px;
          margin-left: 8px;
          border: 1px solid #dee0e7;
          border-radius: 4px;
          background-color: #ffffff;
          cursor: pointer;
          &:hover {
            color: #2474ed;
            background: #ffffff;
            border: 1px solid #2474ed;
          }
        }
        .sea-on {
          color: #2474ed;
          background: #ffffff;
          border: 1px solid #2474ed;
        }
      }
    }
    .paper-res-type {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 14px;
      background: #f9fafc;
      padding: 10px 10px 0;
      div {
        padding: 6px 16px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 8px;
        border: 1px solid #d5d6db;
        white-space: nowrap;
        cursor: pointer;
        background-color: #ffffff;
        &:hover {
          color: #ffffff;
          background: #2474ed;
          border: 1px solid #2474ed;
        }
      }
      .type-list-on {
        color: #ffffff;
        background: #2474ed;
        border: 1px solid #2474ed;
      }
    }
    .scoure-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .type-one {
        &:hover {
          color: #3458c2;
        }
      }
      .type-two {
        &:hover {
          color: #00a0e6;
        }
      }
      .type-three {
        &:hover {
          color: #ff8000;
        }
      }

      > div {
        width: 376px;
        height: 100px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 8px;
        font-size: 20px;
        letter-spacing: 10px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .screen-result {
      .sort-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        .sort-box-on {
          ::v-deep {
            .el-input__inner {
              border-color: #2474ed;
            }
          }
        }
        .el-input {
          width: 300px;
        }
        .el-select {
          margin-right: 8px;
        }
      }
      .paper-list {
        .has-data {
          .list-item {
            padding: 18px;
            border: 1px solid #e9e9e9;
            margin-bottom: 18px;
            transition: 0.2s;
            &:hover {
              border: 1px solid #2474ed;
              .title {
                color: #2474ed !important;
                cursor: pointer;
              }
            }
            //type:1-单元测试 2-月考 3-期中考 4-期末考 5-周考 6-模拟考 7-专题练习 8-入学测验 9-水平会考 10-自主招生 11-联考 12-历年真题 13-竞赛题 14-课时练习 15-学业考试 99-其他
            .com {
              padding: 1px 8px;
              letter-spacing: 2px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              color: #ffffff;
              background-color: #2474ed;
            }
            .bgcolor_1 {
              background-color: #f3f8ff;
              color: #2474ed;
            }
            .bgcolor_2 {
              background-color: #fff8f3;
              color: #ed7e24;
            }
            .bgcolor_3 {
              background-color: #fff7e7;
              color: #f5ab0a;
            }
            .bgcolor_4 {
              background-color: #e8fdf9;
              color: #00c2a2;
            }
            .bgcolor_5 {
              background-color: #e8fcfd;
              color: #0094c2;
            }
            .bgcolor_6 {
              background-color: #f9f1fa;
              color: #af60b3;
            }
            .bgcolor_7 {
              background-color: #e8fded;
              color: #45c200;
            }
            .bgcolor_8 {
              background-color: #fff3f3;
              color: #f76565;
            }
            .bgcolor_9 {
              background-color: #f0efff;
              color: #6465e3;
            }
            .bgcolor_10 {
              background-color: #e8f3fd;
              color: #2474ed;
            }
            .bgcolor_11 {
              background-color: #e8f9ec;
              color: #239f36;
            }
            .bgcolor_12 {
              background-color: #fdf8e8;
              color: #e0a40a;
            }
            .bgcolor_13 {
              background-color: #e8f4fd;
              color: #0067c2;
            }
            .bgcolor_14 {
              background-color: #fff1f1;
              color: #e6596c;
            }
            .bgcolor_15 {
              background-color: #e8f3ff;
              color: #3782ce;
            }
            .bgcolor_99 {
              background-color: #e8f3ff;
              color: #3782ce;
            }
            .head-right-box {
              span {
                color: #5e6a7a;
                margin-left: 14px;
              }
            }
            .paper-tips-box {
              display: flex;
              margin: 4px 0;
              div {
                padding: 3px 8px;
                border-radius: 4px;
                border: 1px solid #ff7303;
                color: #ff7303;
                margin-right: 8px;
              }
              .word-tips {
                border: 1px solid #2474ed;
                color: #2474ed;
              }
              .cut-tips {
                border: 1px solid #149335;
                color: #149335;
              }
              // word-tips cut-tips
            }
            .head {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 6px;
              .title-box {
                display: flex;
                align-items: center;
              }
              .title {
                cursor: pointer;
                color: #0a1119;
                font-weight: 600;
              }
              img {
                width: 16px;
              }
            }
            .type {
              margin: 0px 14px;
              text-align: center;
              border-radius: 4px;
              display: inline-block;
              align-items: center;
            }
            .label-cnt {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              color: #dddddd;
              padding-top: 8px;
              .text {
                .text-btm {
                  display: flex;
                  margin-top: 6px;
                  div {
                    color: #5e6a7a;
                  }
                }
                .normal {
                  color: rgba(0, 0, 0, 1);
                  font-size: 14px;
                  margin: 0 8px;
                  &:first-child {
                    margin-left: 0px;
                  }
                }
              }
              .btns {
                // display: flex;
                position: relative;

                .fx-btn {
                  position: absolute;
                  top: -38px;
                  right: 0;
                }
                .el-button {
                  ::v-deep {
                    span {
                      font-weight: 400;
                    }
                  }
                  img {
                    vertical-align: bottom;
                  }
                  .on {
                    display: none;
                  }
                  &:hover {
                    img {
                      display: none;
                    }
                    .on {
                      display: inline-block;
                    }
                  }
                }
                .el-button-on {
                  color: #2474ed;
                  border-color: #2474ed;
                  img {
                    display: none;
                  }
                  .on {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
        .no-data {
          img {
            width: 400px;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }
  }
}
</style>
